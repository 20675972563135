import React from "react";
import "./SocialLinks.scss";
// import email from "../../assets/logo/email.png";
// import instagram from "../../assets/logo/instagram.png";
// import youtube from "../../assets/logo/youtube.png";
// import chat from "../../assets/logo/chat.png";
// import spotify from "../../assets/logo/spotify.png";
// import {
//   FaInstagram,
//   FaEnvelope,
//   FaYoutube,
//   FaSpotify,
//   FaFacebookMessenger,
//   FaGoodreads,
//   FaLinkedin,
//   FaTiktok,
// } from "react-icons/fa";
import { FiInstagram, FiYoutube, FiMail } from "react-icons/fi";
import { RiMessengerLine } from "react-icons/ri";
import { SlSocialSpotify } from "react-icons/sl";

export default function SocialLinks() {
  const socialLinksData = [
    {
      id: "Gmail",
      href: "mailto:nirireads@gmail.com",
      src: <FiMail />,
    },
    {
      id: "Instagram",
      href: "https://www.instagram.com/igotflowers_/",
      src: <FiInstagram />,
    },
    {
      id: "Messenger",
      href: "https://www.m.me/nirireads",
      src: <RiMessengerLine />,
    },
    {
      id: "Youtube",
      href: "https://www.youtube.com/@nirireads",
      src: <FiYoutube />,
    },
    {
      id: "Spotify",
      href: "https://open.spotify.com/user/31rpwqyafhg4rwhtuhzm5oxcjjga",
      src: <SlSocialSpotify />,
    },
    // {
    //   id: "Goodreads",
    //   href: "https://www.goodreads.com/nirireads",
    //   src: <FaGoodreads />,
    // },
    // {
    //   id: "Tiktok",
    //   href: "https://www.tiktok.com/@igotflowers",
    //   src: <FaTiktok />,
    // },
    // {
    //   id: "Linkedin",
    //   href: "https://www.linkedin.com/in/nirireads/",
    //   src: <FaLinkedin />,
    // },
  ];

  return (
    <div id="socialLinks">
      {socialLinksData.map((socialLink, index) => (
        <div key={index}>
          <a target="_black" rel="noopener nofollow" href={socialLink.href}>
            <div className="social-icon-fill">{socialLink.src}</div>
          </a>
        </div>
      ))}
    </div>
  );
}
