import React, { useEffect, useState } from "react";
import "./Linktree.scss";
import WebsiteLinks from "../../components/WebsiteLinks/WebsiteLinks";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import profileImg from "../../assets/image/profile.jpg";
import Loader from "../../components/Loader/Loader";
import Typing from "react-typing-effect";

export default function Linktree() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleStateChange = () => {
      if (document.readyState === "complete") {
        setLoading(false);
      }
    };

    if (document.readyState === "complete") {
      setLoading(false);
    } else {
      document.addEventListener("readystatechange", handleStateChange);
    }

    return () => {
      document.removeEventListener("readystatechange", handleStateChange);
    };
  }, []);

  return (
    <div className="container">
      {loading ? (
        <Loader />
      ) : (
        <div className="main">
          <img className="profile-img" alt="profile picture" src={profileImg} />
          <h1 className="page-title">Nir Singh</h1>

          <div className="page-bioline">
            किताब , सिनेमा {/* र एल्बम */}
            <Typing
              speed={100}
              eraseSpeed={10}
              typingDelay={10}
              // eraseDelay={00}
              text={[" र एल्बम। ", " , एल्बम र म। "]}
            />
          </div>
          <SocialLinks />
          <WebsiteLinks />
        </div>
      )}
    </div>
  );
}
