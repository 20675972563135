import React from "react";
import "./Wave.scss";

export default function Wave() {
  return (
    <section id="wave">
      <div class="air air1"></div>
      <div class="air air2"></div>
      <div class="air air3"></div>
      <div class="air air4"></div>
    </section>
  );
}
