import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Linktree from "./pages/linktree/Linktree";
// import Portfolio from "./pages/portfolio/Portfolio";
import NotFound from "./pages/notFound/NotFound";
import Website from "./pages/website/Website";
import Wave from "./pages/wave/Wave";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/wave" component={Wave} />
          <Route path="/website" component={Website} />
          <Route path="/" exact component={Linktree} />
          {/* Route for 404 not found */}
          <Route path="/not-found" component={NotFound} />
          {/* Catch- all route for unmatched routes */}
          <Route path="*" render={() => <Redirect to="/not-found" />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
