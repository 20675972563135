import React from "react";
import "./WebsiteLinks.scss";

export default function WebsiteLinks() {
  const websiteLinksData = [
    {
      href: "https://www.linkedin.com/in/nirireads/",
      src: "icons/linkedin.png",
      alt: "linkedin",
    },
    {
      href: "https://github.com/nirireads",
      src: "icons/github.jpg",
      alt: "github",
    },
    {
      href: "https://goodreads.com/nirireads",
      src: "icons/open-book.png",
      alt: "goodreads",
    },
    {
      href: "/website",
      src: "icons/internet.png",
      alt: "website",
    },
  ];

  return (
    <div id="websiteLinks">
      {websiteLinksData.map((websiteLink, index) => (
        <div key={index}>
          <a
            target="_blank"
            rel="noopener nofollow"
            href={websiteLink.href}
            data-id={websiteLink.alt}
          >
            <span>{websiteLink.alt}</span>
          </a>
        </div>
      ))}
    </div>
  );
}
